export default {
  methods: {
    styleCellHighlightingInfection(isSelected, infectionRate) {
      const style = {
        backgroundColor: '',
      };

      if (!isSelected) {
        return style;
      }

      switch (infectionRate) {
        default:
          style.backgroundColor = 'black';
          break;
        case 0:
        case '0':
          style.backgroundColor = '#E8FFED';
          break;
        case 1:
        case '1':
          style.backgroundColor = '#FFFACA';
          break;
        case 2:
        case '2':
          style.backgroundColor = '#FFE4CB';
          break;
        case 3:
        case '3':
          style.backgroundColor = '#FFDADA';
          break;
      }

      return style;
    },
    styleInfectionColor(infectionRate) {
      const style = {
        color: '',
      };

      switch (infectionRate) {
        default:
          style.color = 'black';
          break;
        case 0:
        case '0':
          style.color = '#4FAD64';
          break;
        case 1:
        case '1':
          style.color = '#C5B100';
          break;
        case 2:
        case '2':
          style.color = '#F36701';
          break;
        case 3:
        case '3':
          style.color = '#E32929';
          break;
      }

      return style;
    },
    styleCheckContainer(needCheck) {
      return {
        marginTop: needCheck ? '-12px' : '',
      };
    },
    preparePestValue(pestData) {
      let preparePestValue;

      if (typeof pestData === 'object') {
        _.forEach(pestData, (infectionRate, pestIndex) => {
          preparePestValue = infectionRate;
        });
      }

      return preparePestValue;
    },
    preparePest(pestData) {
      let preparePest;

      if (typeof pestData === 'object') {
        _.forEach(pestData, (infectionRate, pestIndex) => {
          preparePest = pestIndex;
        });
      }

      return preparePest;
    },
    reversePestData(optionalPestData) {
      if (!optionalPestData || !optionalPestData.id) {
        return 0;
      }
      return {
        [optionalPestData.id]: optionalPestData.rate,
      };
    },
    getOptionalPestData(pestList, selectedPest) {
      const optionalPestData = {
        id: 0,
        rate: 0,
      };

      _.forEach(pestList, (infectionRate, pestIndex) => {
        if (parseInt(pestIndex) === parseInt(selectedPest)) {
          optionalPestData.id = parseInt(pestIndex);
          optionalPestData.rate = parseInt(infectionRate);
        }
      });

      return optionalPestData;
    },
    parsePestData(pestList) {
      const parsedPests = [];

      _.forEach(pestList, (infectionRate, pestIndex) => {
        parsedPests.push({
          id: pestIndex,
          rate: infectionRate,
        });
      });

      return parsedPests;
    },
  },
};
