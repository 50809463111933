<script>
export default {
  name: "LanguageSelect",
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
  },
  data: () => ({
    open: false,
    language: 'ru',
    options: [
      {
        label: 'Русский',
        value: 'ru',
        flagUrl: 'https://img.icons8.com/color/96/russian-federation-circular.png',
      },
      {
        label: 'Polski',
        value: 'pl',
        flagUrl: 'https://img.icons8.com/color/96/poland-circular.png',
      }
    ],
  }),
  computed: {
    selectedLanguage() {
      return this.options.find(lang => lang.value === this.language);
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },

    selectLanguage(code) {
      this.language = code;

      this.open = false;
    },
  },
};
</script>

<template>
    <div class="language-select" :class="`language-select__${position}`">
        <button class="language-select-trigger" @click="toggle">
            <img :src="selectedLanguage.flagUrl" class="language-select-flag" alt="Flag" />
            <span>{{ selectedLanguage.label }}</span>
            <svg
                class="language-select-caret" :class="open ? 'language-select-caret-open' : ''"
                xmlns="http://www.w3.org/2000/svg"
                width="18" height="18"
                viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"
            >
                <path d="m18 15-6-6-6 6" />
            </svg>
        </button>

        <div v-if="open" class="language-select-dropdown">
            <button
                v-for="option in options"
                :key="option.value"
                class="language-select-dropdown-item"
                @click="selectLanguage(option.value)"
            >
                <img :src="option.flagUrl" class="language-select-flag" alt="Flag" />
                <span>{{ option.label }}</span>
            </button>
        </div>
    </div>
</template>
